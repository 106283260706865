import React from 'react'
import ContactUs from '../components/Layout/ContactUs/ContactUs'

const Contact = () => {
    return (
        <div>
            <ContactUs />
        </div>
    )
}

export default Contact
