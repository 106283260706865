import React from 'react'
import NextEvent from '../components/Layout/NextEvent/NextEvent'

const Event = () => {
    return (
        <div>

            <div>
                <div>
                    <NextEvent />
                </div>
            </div>


        </div>
    )
}

export default Event
