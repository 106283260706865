import React from 'react'
import RunIncShop from '../components/Layout/RunIncShop/RunIncShop'

const Shop = () => {
    return (
        <div>
            <RunIncShop />
        </div>
    )
}

export default Shop
